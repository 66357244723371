"use strict";
/* Polyfill for Set intersection */
if (!Set.prototype.intersection) {
    Set.prototype.intersection = function (otherSet) {
        if (otherSet.size > this.size) {
            return otherSet.intersection(this);
        }
        const out = new Set();
        for (const elem of otherSet) {
            if (this.has(elem)) {
                out.add(elem);
            }
        }
        return out;
    };
}
/* Polyfill for Set difference */
if (!Set.prototype.difference) {
    Set.prototype.difference = function (otherSet) {
        const out = new Set();
        for (const elem of this) {
            if (!otherSet.has(elem)) {
                out.add(elem);
            }
        }
        return out;
    };
}
if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (search, replacement) {
        return this.split(search).join(replacement);
    };
}
